import React, { MouseEventHandler, useState } from "react";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { MoreDrawer } from "@/components/blocks/global/more-drawer/MoreDrawer";
import { NextLinkComposed } from "@/components/elements/link/DefaultLink";
import { Box, Button, Typography, useTheme } from "@mui/material";

import FryingIcon from "~public/icons/Frying/Frying.svg";
//icons
import HomeIcon from "~public/icons/Home/Home.svg";
import MenuSplash from "~public/icons/Menu Selected Splash/Menu Selected Splash.svg";
import MoreCloseIcon from "~public/icons/More/More Close.svg";
import MoreIcon from "~public/icons/More/More.svg";
import ProfileIcon from "~public/icons/Profile/Profile.svg";

interface NavButtonBaseProps {
  label: string;
  path?: string;
  icon: typeof MenuSplash;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isDrawerOpen: boolean;
  name: string;
}

const NavButtonBase = ({
  label,
  path,
  icon,
  onClick,
  isDrawerOpen,
  name
}: NavButtonBaseProps) => {
  const router = useRouter();
  const theme = useTheme();
  const isSelected = isDrawerOpen ? name === "more" : router.pathname === path;
  const Icon = icon;
  return (
    <Button
      onClick={onClick}
      variant={"text"}
      component={NextLinkComposed}
      sx={{
        m: 0,
        p: 0,
        gap: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        "& .splash path": { fill: theme.palette.pink.main },
        "& .nav-icon path": { fill: "default" }
      }}
      to={{
        pathname: path
      }}
      id={`navbar-button-${name}`}
      data-qa={`navbar-button-${name}`}>
      <Icon className="nav-icon" style={{ fontSize: 24, zIndex: 2 }} />
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          textTransform: "none",
          zIndex: 1
        }}>
        {label}
      </Typography>
      <MenuSplash
        className="splash"
        style={{
          position: "absolute",
          visibility: isSelected ? "visible" : "hidden",
          top: 0,
          left: 11,
          zIndex: 0,
          fontSize: 43
        }}
      />
    </Button>
  );
};

export const NavBar = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const Home = () => (
    <NavButtonBase
      icon={HomeIcon}
      label={t("home")}
      path="/"
      name="home"
      isDrawerOpen={isOpen}
      onClick={handleClose}
    />
  );
  const Recipes = () => (
    <NavButtonBase
      icon={FryingIcon}
      label={t("recipes")}
      path="/search"
      name="search"
      isDrawerOpen={isOpen}
      onClick={handleClose}
    />
  );
  const Profile = () => {
    return (
      <NavButtonBase
        icon={ProfileIcon}
        label={t("profile")}
        path={"/profile"}
        name="profile"
        isDrawerOpen={isOpen}
        onClick={handleClose}
      />
    );
  };
  const More = () => (
    <NavButtonBase
      icon={isOpen ? MoreCloseIcon : MoreIcon}
      label={t("more")}
      onClick={toggleDrawer}
      isDrawerOpen={isOpen}
      name="more"
    />
  );

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        component={"nav"}
        bgcolor={"white.main"}
        sx={{
          boxShadow: 3,
          overflow: "hidden",
          justifyContent: "space-evenly",
          alignItems: "center",
          position: "fixed",
          height: theme.variables.navBarHeight,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000
        }}>
        <Home />
        <Recipes />
        <Profile />
        <More />
      </Box>
      <MoreDrawer isOpen={isOpen} handleClose={handleClose} />
    </React.Fragment>
  );
};
