import React from "react";

import { $isReviewModalOpen, toggleReviewModal } from "@/customHooks/useReview";
import { useStore } from "effector-react";

import { Footer } from "@/components/blocks/global/footer/Footer";
import { Header } from "@/components/blocks/global/header/Header";
import { NavBar } from "@/components/blocks/global/nav-bar/NavBar";
import { ReviewModal } from "@/components/blocks/global/review-modal/ReviewModal";
import { Box, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";

interface LayoutProps {
  children: React.ReactNode;
  noHeader?: boolean;
  noNavBar?: boolean;
  noFooter?: boolean;
  sx?: SxProps<Theme>;
  mainSx?: SxProps<Theme>;
  promo?: boolean;
  headerColor?: string;
  headerLeftIcon?: string | null;
  headerRightIcon?: string | null;
  uber?: boolean;
}

const Layout = ({
  children,
  noHeader,
  noNavBar,
  noFooter,
  sx,
  mainSx,
  promo,
  headerColor,
  headerRightIcon,
  headerLeftIcon,
  uber
}: LayoutProps): JSX.Element => {
  const isModalOpen = useStore($isReviewModalOpen);

  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));
  const headerHeight = noHeader ? theme.variables.headerHeight : "0px";
  const navBarHeight = noNavBar ? theme.variables.navBarHeight : "0px";
  return (
    <>
      <Box
        sx={{
          ...sx,
          display: "flex",
          flexFlow: "column",
          mb: noNavBar
            ? 0
            : isGreaterThanTablet
            ? "0px"
            : theme.variables.navBarHeight
        }}>
        {!noHeader && (
          <Header
            uber={uber}
            leftIcon={headerLeftIcon}
            rightIcon={headerRightIcon}
            color={headerColor}
            promo={promo}
          />
        )}

        <Box
          component={"main"}
          sx={{
            ...mainSx,
            position: "relative",
            flexGrow: 1,
            minHeight: `calc(100vh - ${headerHeight} - ${
              isGreaterThanTablet ? "80px" : navBarHeight
            })`,
            overflowX: "hidden",
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            mt: noHeader ? 0 : { xs: theme.variables.headerHeight, md: "80px" }
          }}>
          {children}
        </Box>

        {!noFooter && <Footer />}
        {!noNavBar && !isGreaterThanTablet && <NavBar />}
      </Box>
      <ReviewModal open={isModalOpen} onClose={toggleReviewModal} />
    </>
  );
};

export default Layout;
