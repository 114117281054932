import { useState } from "react";

import AccountApi from "@/api/Account";

import {
  SignInResponse,
  UseSessionOptions,
  signIn,
  useSession
} from "next-auth/react";
import { useTranslation } from "next-i18next";
import { AccountApiNS } from "~types/api/account";

import RegisterArgs = AccountApiNS.RegisterArgs;
import LoginArgs = AccountApiNS.LoginArgs;

export async function fetchSession() {
  const res = await fetch("/api/auth/session");
  const session = await res.json();
  if (Object.keys(session).length) {
    return session;
  }
  return null;
}

export const useAuth = (options?: UseSessionOptions<boolean>) => {
  const { data: session, status } = useSession(options);
  // return [session, Boolean(status === "loading")];
  return { session, isLoading: status === "loading" };
};

export const useLogin = () => {
  const { t } = useTranslation("errors");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ok, setOk] = useState<boolean>();
  const [status, setStatus] = useState<number>();
  const [error, setError] = useState<string | undefined>(undefined);

  const login = async (
    provider: string,
    options?: {
      data: LoginArgs;
      onSuccess?: (res?: SignInResponse | undefined) => void;
      onError?: (res?: SignInResponse | undefined) => void;
      redirect?: boolean | undefined;
      callbackUrl?: string | undefined;
    }
  ) => {
    setIsLoading(true);
    const res = await signIn(provider, {
      ...options?.data,
      redirect: options?.redirect || false,
      callbackUrl: options?.callbackUrl
    });
    setIsLoading(false);
    setStatus(status);
    setOk(ok);

    if (res?.ok) {
      setError(undefined);
      if (options?.onSuccess) {
        options.onSuccess(res);
      }
      return;
    }
    if (!res?.ok) {
      if (res?.status === 401) {
        setError(t("auth.wrong-credentials"));
      } else {
        setError(t("default.went-wrong"));
      }

      if (options?.onError) {
        options.onError(res);
      }
      return;
    }

    setStatus(status);
    setOk(ok);
  };

  return {
    login,
    error,
    status,
    isSuccess: ok,
    isLoading
  };
};

export const useRegister = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();
  const [error, setError] = useState<string | undefined>(undefined);

  const register = async (
    data: RegisterArgs,
    options: { onSuccess?: () => void; onError?: () => void }
  ) => {
    try {
      setIsLoading(true);
      const response = await AccountApi.register(data);
      if (options?.onSuccess) {
        options?.onSuccess();
      }
      setStatus(201);
    } catch (e) {
      console.log({ e });
      if (e?.response?.status === 400 || e?.response?.status === 403) {
        setError(e?.response?.data?.email);
      } else {
        setError("Something went wrong");
      }
      if (options?.onError) {
        options?.onError();
      }
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    error,
    status,
    isLoading
  };
};
