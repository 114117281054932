import { createEvent, createStore } from "effector";

//events
export const setFreeRecipeId = createEvent<number | null>();

//effects

//store
export const $freeRecipeId = createStore<number | null>(null).on(
  setFreeRecipeId,
  (state, payload) => {
    if (state === payload) return null;
    return payload;
  }
);
