import CartApi from "@/api/Cart";
import RecipeApi from "@/api/Recipe";

import { $freeRecipeId } from "@/features/free-recipe-feature/store";
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample
} from "effector";
import { useStore } from "effector-react";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { CartApiNS } from "~types/api/cart";
import { Recipe } from "~types/recipe";

import { useQueriesTyped } from "./useQueriesTyped";

enum Keys {
  Profile = "profile",
  AddressMain = "address_main",
  AddressAlt = "address_alt",
  Cart = "cart",
  CartClear = "cart_clear",
  CartInfo = "cart_info"
}

interface PromoCode {
  amount: number;
  promocode: string;
  promocode_type: number;
}

export const getDiscount = createEvent<number>();
export const resetCoupon = createEvent();
export const resetDiscount = createEvent();

export const checkPromoFx = createEffect(async (promocode: string) => {
  return await CartApi.checkPromo(promocode);
});

export const calculateTotalPriceFx = createEffect((recipes: any) => {
  return recipes?.reduce(
    (sum: number, recipe: { data: { count: any; object: { price: any } } }) => {
      return (
        sum +
        Number(recipe?.data?.count || 0) * Number(recipe?.data?.object.price)
      );
    },
    0
  );
});

//deliveryType
export const changeDeliveryType = createEvent<"delivery" | "pickup">();
export const $deliveryType = createStore<"delivery" | "pickup">("pickup").on(
  changeDeliveryType,
  (_state, payload) => payload
);

export const $totalPrice = createStore(0).on(
  calculateTotalPriceFx.doneData,
  (_state, payload) => {
    return payload;
  }
);

export const $discount = createStore(0)
  .on(getDiscount, (_state, payload) => payload)
  .on(resetCoupon, () => 0);

export const $promoError = createStore("").on(
  checkPromoFx.fail,
  () => "wrong promo"
);
export const $promoInfo = createStore<PromoCode>({
  amount: 0,
  promocode_type: 0,
  promocode: ""
})
  .on(checkPromoFx.doneData, (_state, payload) => payload)
  .on(resetCoupon, (state, payload) => ({
    ...state,
    promocode: "",
    promocode_type: 0,
    amount: 0
  }));

export const $promocode = $promoInfo.map(state => state.promocode);

export const $result = combine(
  $totalPrice,
  $promoInfo,
  (totalPrice, promoInfo) => {
    if (promoInfo?.promocode_type === 1) {
      const finalPrice = totalPrice - promoInfo?.amount;

      return finalPrice >= 0 ? finalPrice : 0;
    }
    if (promoInfo?.promocode_type === 2) {
      const finalPrice = totalPrice * ((100 - promoInfo?.amount) / 100);
      return finalPrice >= 0 ? finalPrice : 0;
    }

    return totalPrice;
  }
);

sample({
  clock: $result,
  source: { finalPrice: $result, subtotalPrice: $totalPrice },
  fn: ({ finalPrice, subtotalPrice }) => {
    return subtotalPrice - finalPrice;
  },
  target: getDiscount
});

export const useCart = () => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const useCartShort = (
    options?: UseQueryOptions<CartApiNS.GetProductsResponse>
  ) => useQuery([Keys.Cart], CartApi.getProducts, options);

  const useDeliveryPrice = () =>
    useQuery(["delivery_price"], CartApi.getDeliveryPrice);

  const useEngagementFee = () =>
    useQuery(["engagement_fee"], CartApi.getEngagementFee);

  const useCartFull = () => {
    const { data: cartShort } = useCartShort();
    const { data: deliveryPrice } = useDeliveryPrice();
    const { data: engagementFee } = useEngagementFee();
    const promoCode = useStore($promoInfo);
    const total = useStore($result);
    const discount = useStore($discount);
    const deliveryType = useStore($deliveryType);
    const checkedRecipeId = useStore($freeRecipeId);

    const queries = cartShort?.results?.map(product => {
      return {
        queryKey: ["recipe", product?.object_id, router?.locale],
        queryFn: () =>
          RecipeApi.getRecipe({
            recipeId: product?.object_id,
            lang: router?.locale
          }),
        select: (data: Recipe) => ({ ...product, object: data })
      };
    });
    const recipes = useQueriesTyped(queries || []);

    calculateTotalPriceFx(recipes);

    const freeRecipeSum =
      recipes.find(recipe => recipe?.data?.object_id === checkedRecipeId)?.data
        ?.object?.price ?? 0;

    return {
      data: {
        count: cartShort?.count,
        total: total - Number(freeRecipeSum),
        products: recipes?.map(recipe => recipe.data)
      },
      isLoading: recipes?.some(recipe => recipe.isLoading),
      delivery: deliveryType === "delivery" ? deliveryPrice?.price || 0 : 0,
      engagement: !checkedRecipeId ? engagementFee?.fee || 0 : 0,
      discount: discount
    } as const;
  };

  const useCartAdd = useMutation<any, AxiosError, CartApiNS.AddToCartArgs, any>(
    ({ id, state }) => CartApi.addToCart({ id, state }),
    {
      onMutate: async ({ id, state }) => {
        await queryClient.cancelQueries([Keys.Cart]);
        const previousCartShort = await queryClient.getQueryData([Keys.Cart]);
        queryClient.setQueryData(
          [Keys.Cart],
          (cartShort: CartApiNS.GetProductsResponse) => {
            const filtered =
              cartShort?.results?.filter(
                product => product.object_id !== id && product.state !== state
              ) || [];
            const newResults = [
              ...filtered,
              { count: 1, object_id: id, state }
            ];
            return {
              ...cartShort,
              count: newResults.length,
              results: newResults
            };
          }
        );
        return { previousCartShort };
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData([Keys.Cart], context?.previousCartShort);
        if (error.response?.status === 401) {
          toast.error("Please login to add products in your cart");
          router.push("/login");
        }
        // toast.error(err?.message || "Can't add to cart. Check network connection");
        toast.error(
          JSON.stringify(Object.values(error?.response?.data).flat()) ||
            "Can't add to cart. Check network connection"
        );
      }
    }
  );

  const useCartUpdate = useMutation<
    CartApiNS.UpdateInCartResponse,
    AxiosError,
    CartApiNS.UpdateInCartArgs,
    any
  >(({ id, count, is_free }) => CartApi.updateInCart({ id, count, is_free }), {
    onMutate: async ({ id, count, is_free }) => {
      await queryClient.cancelQueries([Keys.Cart]);
      const previousCartShort = queryClient.getQueryData([Keys.Cart]);
      queryClient.setQueryData(
        [Keys.Cart],
        (cartShort: CartApiNS.GetProductsResponse) => {
          const newResults = cartShort.results.map(product =>
            product.pk === id
              ? { ...product, count, is_free }
              : { ...product, is_free: false }
          );

          return {
            ...cartShort,
            count: newResults?.length,
            results: newResults
          };
        }
      );

      return { previousCartShort };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData([Keys.Cart], context.previousCartShort);
      toast.error("Can't change product count. Check network connection");
    }
  });

  const useCartRemove = useMutation<
    any,
    AxiosError,
    CartApiNS.RemoveFromCartArgs,
    any
  >(({ id }) => CartApi.removeFromCart({ id }), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries([Keys.Cart]);
      const previousCartShort = queryClient.getQueryData([Keys.Cart]);
      queryClient.setQueryData(
        [Keys.Cart],
        (cartShort: CartApiNS.GetProductsResponse) => {
          const newResults = cartShort.results.filter(
            product => product.pk !== id
          );

          return {
            ...cartShort,
            count: newResults.length,
            results: newResults
          };
        }
      );
      return { previousCartShort };
    },
    onError: (err, newCartShort, context) => {
      queryClient.setQueryData([Keys.Cart], context.previousCartShort);
      toast.error("Can't remove product. Check network connection");
    }
  });

  const useCartClear = useMutation<any, AxiosError, any, any>(
    async () => {
      const cart =
        await queryClient.getQueryData<CartApiNS.GetProductsResponse>([
          Keys.Cart
        ]);
      return Promise.all(
        cart!.results.map(product => CartApi.removeFromCart({ id: product.pk }))
      );
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([Keys.Cart]);
        const previousCartShort = queryClient.getQueryData([Keys.Cart]);
        await queryClient.setQueryData([Keys.CartClear], previousCartShort);
        queryClient.setQueryData([Keys.Cart], {
          count: 0,
          results: []
        });
        return { previousCartShort };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData([Keys.Cart], context.previousCartShort);
        toast.error("Can't clear cart. Check network connection");
      },
      onSuccess: () => {
        queryClient.invalidateQueries([Keys.Cart]);
        queryClient.removeQueries([Keys.CartClear]);
      }
    }
  );

  const useIsInCart = ({
    id,
    state
  }: Partial<CartApiNS.AddToCartArgs> &
    Pick<CartApiNS.AddToCartArgs, "id">) => {
    const cartShort = queryClient?.getQueryData<CartApiNS.GetProductsResponse>([
      Keys.Cart
    ]);
    if (!cartShort) {
      return false;
    }
    return cartShort?.results?.some(product => {
      if (state) {
        return product.object_id === id && product.state === state;
      }
      return product.object_id === id;
    });
  };

  const useCartInfo = (options?: UseQueryOptions) =>
    useQuery([Keys.CartInfo], CartApi.getInfo, options);

  return {
    short: useCartShort,
    full: useCartFull,
    add: useCartAdd,
    update: useCartUpdate,
    remove: useCartRemove,
    clear: useCartClear,
    isInCart: useIsInCart,
    info: useCartInfo
  };
};
