import React, { ReactNode } from "react";

import { useTranslation } from "next-i18next";

import DefaultLink from "@/components/elements/link/DefaultLink";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
  useTheme
} from "@mui/material";

import Facebook from "~public/icons/Facebook/Facebook.svg";
import Instagram from "~public/icons/Instagram/Instagram.svg";
//icons
import Twitter from "~public/icons/Twitter/Twitter.svg";
import YouTube from "~public/icons/YouTube/YouTube.svg";
import Logo from "~public/images/logo-sign.svg";

interface FooterLinkProps {
  body: React.ReactNode;
  path: string;
  disabled?: boolean;
}

const FooterLink = ({ body, path, disabled }: FooterLinkProps) => (
  <DefaultLink
    sx={{ color: "white.main", pointerEvents: disabled ? "none" : "auto" }}
    href={path}>
    <Typography color={"#fff"} fontWeight={14} fontSize={16}>
      {body}
    </Typography>
  </DefaultLink>
);

interface FooterLinksGroupProps {
  title: string;
  children: ReactNode;
}

const FooterLinksGroup = ({
  title,
  children
}: FooterLinksGroupProps): JSX.Element => (
  <Box
    className="links_block"
    sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
    <Typography mb={1} fontWeight={600} fontSize={16} color={"#a1a1a1"}>
      {title}
    </Typography>
    {children}
  </Box>
);

export const Footer = (): JSX.Element => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  return (
    <Box
      component={"footer"}
      sx={{
        px: [2, 4, 8, 16, 40, 48],
        py: 2.5,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        color: "white.main",
        backgroundColor: theme.palette.primary.main,
        rowGap: 2.5,
        "& .links_block": {
          flexBasis: 155
        },
        zIndex: 1000
      }}>
      <DefaultLink
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          order: -2,
          "& svg": {
            width: 186,
            height: 31
          }
        }}
        href="/">
        <Logo />
      </DefaultLink>
      <FooterLinksGroup title={t("ourContent")}>
        <FooterLink body={t("recipes")} path={"/search"} />
        <FooterLink body={t("homechefs")} path={"/chefs"} />
        <FooterLink body={t("favourite")} path={"/search?tab=favourites"} />
        <FooterLink body={t("cookies")} path={"/cookies"} />
      </FooterLinksGroup>
      <FooterLinksGroup title={t("ourProduct")}>
        <FooterLink body={t("notifications")} path={"/notifications"} />
        <FooterLink body={t("settings")} path={"/profile?tab=settings"} />
        <FooterLink body={t("faq")} path={"/faq"} />
      </FooterLinksGroup>
      <FooterLinksGroup title={t("company")}>
        <FooterLink body={t("aboutUs")} path={"/about"} />
        <FooterLink body={t("contactUs")} path={"/contacts"} />
        <FooterLink body={t("termsOfService")} path={"/terms"} />
        <FooterLink body={t("privacyPolicy")} path={"/privacy-policy"} />
      </FooterLinksGroup>
      <FooterLinksGroup title={"Social media"}>
        <Box sx={{ display: "flex", gap: 1.5 }}>
          <DefaultLink target={"_blank"} href="https://facebook.com/cookachu1">
            <Facebook style={{ fontSize: 24 }} />
          </DefaultLink>
          <DefaultLink target={"_blank"} href="https://instagram.com/cookachu1">
            <Instagram style={{ fontSize: 24 }} />
          </DefaultLink>
          <DefaultLink target={"_blank"} href="https://twitter.com/cookachu1">
            <Twitter style={{ fontSize: 24 }} />
          </DefaultLink>
          <DefaultLink target={"_blank"} href="https://youtube.com/cookachu1">
            <YouTube style={{ fontSize: 24 }} />
          </DefaultLink>
        </Box>
      </FooterLinksGroup>
      <Box
        component={"form"}
        method={"post"}
        action={
          // eslint-disable-next-line max-len
          "https://mailer.i.bizml.ru/forms/simple/u/eyJ1c2VyX2lkIjoyMDAwMDQ0OTQzLCJhZGRyZXNzX2Jvb2tfaWQiOjkyMDE1NiwibGFuZyI6InJ1In0="
        }
        sx={{
          width: ["100%", "100%", "auto"],
          order: [0, 0, -1]
        }}>
        <InputLabel
          sx={{
            color: "white.main",
            fontWeight: 400,
            fontSize: 14
          }}></InputLabel>
        <TextField
          type={"email"}
          required
          name={"email"}
          fullWidth
          label={t("footer.title")}
          placeholder={t("footer.placeholder")}
          sx={{
            bgcolor: "yellow",
            mb: 2,
            "& input": { color: theme.palette.white.main },
            "& label": { color: "white !important" },
            "& .MuiInput-underline:after": {
              borderBottomColor: "green"
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white.main"
              },
              "&:hover fieldset": {
                borderColor: "#E0E0E0"
              },
              "&.Mui-focused fieldset": {
                borderColor: "#E0E0E0"
              }
            }
          }}
        />
        <input type="hidden" name="sender" value="sendbox@goodbit.dev" />
        <Button
          variant={"contained"}
          color={"green"}
          sx={{
            height: 41,
            borderRadius: 12,
            textTransform: "none"
          }}
          fullWidth
          type={"submit"}>
          {t("subscribe")}
        </Button>
      </Box>
    </Box>
  );
};
