import React, { SyntheticEvent, useEffect, useRef, useState } from "react";

import RecipeApi from "@/api/Recipe";

import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { SwipeableEdgeDrawer } from "@/components/elements/bottom-drawer-with-edge/BottomDrawerWithEdge";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import NotFoundIcon from "~public/icons/Not Found/Not found.svg";

export const SearchDrawer = ({ isOpen, toggleDrawer }: any): JSX.Element => {
  const router = useRouter();
  const [title, setTitle] = useState<string>("");
  const { data, isFetching, remove } = useQuery(
    ["search-suggestions", title],
    () => RecipeApi.getSearchSuggestions("en", title),
    {
      enabled: !!title,
      keepPreviousData: true
    }
  );

  const textFieldRef = useRef<HTMLInputElement>();

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    router.push({ pathname: "/search-results", query: { title } });
  };

  const onClick = (text: string) => {
    setTitle(text);
    router
      .push({ pathname: "/search-results", query: { title: text } })
      .then(() => toggleDrawer())
      .then(() => setTitle(""))
      .then(() => remove());
  };

  useEffect(() => {
    if (textFieldRef.current && isOpen && !title) {
      textFieldRef.current?.focus();
    }
  }, [isOpen, textFieldRef.current]);

  return (
    <SwipeableEdgeDrawer
      sx={{
        maxWidth: "976px",
        margin: "0 auto",
        width: "100%"
      }}
      backgroundColor="#F9FAFB"
      isOpen={isOpen}
      toggleDrawer={toggleDrawer}>
      <form onSubmit={handleSubmit}>
        <TextField
          autoFocus
          inputRef={textFieldRef}
          value={title}
          onChange={e => setTitle(e.target.value)}
          id={"search_drawer-search-input"}
          data-qa={"search_drawer-search-textfield"}
          InputProps={{
            startAdornment: isFetching ? (
              <CircularProgress size={24} />
            ) : (
              <SearchIcon />
            )
          }}
          fullWidth
        />
      </form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF"
        }}>
        {!isFetching &&
          data?.map((el: { result: string }, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  padding: "6px 16px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(224, 224, 224, 0.3)"
                  }
                }}>
                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 20 }
                  }}
                  onClick={() => onClick(el?.result)}>
                  {el.result}
                </Typography>
              </Box>
            );
          })}
      </Box>
      {!isFetching && !data?.length && title && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}>
          <NotFoundIcon width={50} height={50} />
        </Box>
      )}
    </SwipeableEdgeDrawer>
  );
};
