import React, { useEffect, useState } from "react";

import { useAuth } from "@/customHooks/useAuth";
import { useCart } from "@/customHooks/useCart";
import { navigation } from "@/utils/navigation";

import { LanguageSelector } from "@/components/blocks/global/language-selector/LanguageSelector";
import { NavBarDesktop } from "@/components/blocks/global/nav-bar-desktop/NavBarDesktop";
import { SearchDrawer } from "@/components/blocks/global/search-drawer/SearchDrawer";
import DefaultLink from "@/components/elements/link/DefaultLink";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";

import CookachuIcon from "~public/icons/PoweredByCookachu.svg";
import Profile from "~public/icons/Profile/Profile.svg";
//icons
import SearchIcon from "~public/icons/Search/Search.svg";
import ShoppingBag from "~public/icons/Shopping Bag/Shopping Bag.svg";
import UberIcon from "~public/icons/Uber.svg";
import Logo from "~public/images/logo-sign.svg";

import { LoginMenu } from "../login-menu/LoginMenu";
import {useRouter} from "next/router";

interface Props {
  promo?: boolean;
  color?: string;
  leftIcon?: string | null;
  rightIcon?: string | null;
  uber?: boolean;
}
export const Header = ({ promo, color, leftIcon, rightIcon, uber }: Props) => {
  const { session } = useAuth();
  const { short } = useCart();
  const { data: cart, refetch } = short({ enabled: false });
  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState<boolean>(false);

  const handleToggleDrawer = () => {
    setIsSearchDrawerOpen(prev => !prev);
  };

  const handleOpenDrawer = () => {
    setIsSearchDrawerOpen(true);
  };

  useEffect(() => {
    if (session) {
      refetch();
    }
  }, [cart]);

  //{company}/{id}
  //uber/{id}
  //id/{}


  const route = router.asPath.includes("uber") ? "uber" : (Array.isArray(router.query?.id) && router.query?.id?.length > 1 ? router.query?.id?.[0] : "");
  const iconLink = leftIcon ? `/welcome/${route}` : (route === "uber" ? "/uber" : "/welcome");

  return (
    <Paper
      component={"header"}
      sx={{
        bgcolor: promo ? (color ? color : "#000000") : "primary.main",
        pl: 2.25,
        pr: 1.25,
        color: "white.main",
        position: "fixed",
        height: { xs: theme.variables.headerHeight, md: "80px" },
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 2,
        zIndex: 1000
      }}
      square
      elevation={0}>
      <Box
        sx={{ marginRight: "auto", marginLeft: 0 }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}>
        <DefaultLink
          sx={{
            display: "flex",
            alignItems: "center"
          }}
          href={iconLink}>
          {promo ? (
            <Box
              sx={{
                maxWidth: "114px",
                display: "flex",
                alignItems: "center",
                // maxWidth: { xs: "57px", md: "114px" },

                "& > svg": {
                  width: { xs: "57px", md: "114px" },
                  height: { xs: "24px", md: "48px" },
                  fill: "#FFFFFF"
                },
                "& path": {
                  fill: "#FFFFFF"
                }
              }}>
              {leftIcon ? (
                <img
                  style={{ objectFit: "contain", width: "100%" }}
                  src={leftIcon}
                  alt="icon"
                />
              ) : uber ? (
                <UberIcon />
              ) : null}
            </Box>
          ) : (
            <Logo
              style={{
                height: isGreaterThanTablet ? 41 : 24,
                width: isGreaterThanTablet ? 210 : 122
              }}
            />
          )}
        </DefaultLink>
        {!promo && isGreaterThanTablet && <NavBarDesktop />}
      </Box>
      {!promo && isGreaterThanTablet && <LanguageSelector color={"white"} />}
      {!promo && (
        <IconButton onClick={handleOpenDrawer}>
          <SearchIcon
            style={{ fontSize: 24, display: "flex", alignItems: "center" }}
          />
        </IconButton>
      )}
      {!promo && (
        <DefaultLink
          data-cy="go_to_cart_link"
          href={session ? navigation.cart : navigation.login}>
          <Badge
            badgeContent={cart?.count}
            sx={{
              color: "pink.main",
              "& .MuiBadge-badge": {
                bgcolor: "white.main",
                fontWeight: 300
              }
            }}>
            <ShoppingBag
              style={{
                fontSize: 24,
                marginRight: 0,
                display: "flex",
                alignItems: "center"
              }}
            />
          </Badge>
        </DefaultLink>
      )}

      {!promo && (
        <>
          {isGreaterThanTablet && (
            <>
              <Divider
                variant={"middle"}
                color={"white"}
                flexItem
                orientation={"vertical"}
              />
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ "& path": { fill: theme.palette.white.main } }}>
                <Profile
                  style={{
                    fontSize: 24
                  }}
                />
              </IconButton>
            </>
          )}
          <SearchDrawer
            isOpen={isSearchDrawerOpen}
            toggleDrawer={handleToggleDrawer}
          />
          {isGreaterThanTablet && (
            <LoginMenu
              isOpen={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
            />
          )}
        </>
      )}

      {promo && (
        <DefaultLink
          href={"/welcome"}
          sx={{
            maxWidth: "190px",
            width: { xs: "auto", md: "100%" },
            "& > svg": {
              width: { xs: "95px", md: "190px" },
              height: { xs: "26px", md: "52px" }
            }
          }}>
          {rightIcon ? (
            <img
              style={{ objectFit: "contain", width: "100%" }}
              src={rightIcon}
              alt="icon"
            />
          ) : uber ? (
            <CookachuIcon />
          ) : null}
        </DefaultLink>
      )}
    </Paper>
  );
};
