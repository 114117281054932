import React, { useState } from "react";

import { MemoizedTextField } from "@/components/elements/memoized-textfield/MemoizedTextField";
import { Box, FormLabel, SxProps, TextFieldProps, Theme } from "@mui/material";

interface ITextFieldWithMaxSymbols {
  maxLength: number;
  HelpTextSx?: SxProps<Theme>;
  top?: string;
}

export const TextFieldWithMaxSymbols = (
  props: ITextFieldWithMaxSymbols & TextFieldProps
): JSX.Element => {
  const {
    maxLength,
    value,
    inputProps,
    onChange: onChangeProp,
    HelpTextSx,
    top,
    ...otherProps
  } = props as any;
  const [valueLength, setValueLength] = useState<number>(
    value?.length ? value.length : 0
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onChangeProp) {
      onChangeProp(e);
    }
    setValueLength(e.target.value.length);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <FormLabel
        sx={{
          ...HelpTextSx,
          position: "absolute",
          right: 0,
          top: top ? top : 0,
          fontWeight: 300,
          fontSize: 14
        }}>
        {valueLength}/{maxLength}
      </FormLabel>
      <MemoizedTextField
        onChange={onChange}
        value={value}
        inputProps={
          { maxLength, ...inputProps } as TextFieldProps["InputProps"]
        }
        {...otherProps}
      />
    </Box>
  );
};
