import React from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Box, Button, PaletteColor } from "@mui/material";

interface LanguageSelectorProps {
  color: PaletteColor;
}

export const LanguageSelector = ({ color }: any): JSX.Element => {
  const router = useRouter();

  const resultColor = color ? color : "primary";
  return (
    <Box my={2.5} sx={{ display: "flex" }}>
      <Link href={router.asPath} locale={"nl"}>
        <Button
          sx={{
            fontSize: 16,
            "::after":
              router.locale === "nl"
                ? {
                    content: "''",
                    width: "8px",
                    height: "4px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "4px 4px 0 0",
                    position: "absolute",
                    bottom: 0
                  }
                : {}
          }}
          color={resultColor}
          variant={"text"}>
          NL
        </Button>
      </Link>
      <Link href={router.asPath} locale={"en"}>
        <Button
          sx={{
            fontSize: 16,
            "::after":
              router.locale === "en"
                ? {
                    content: "''",
                    width: "8px",
                    height: "4px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "4px 4px 0 0",
                    position: "absolute",
                    bottom: 0
                  }
                : {}
          }}
          color={resultColor}
          variant={"text"}>
          EN
        </Button>
      </Link>
    </Box>
  );
};
