import React from "react";

import { useAuth } from "@/customHooks/useAuth";
import dayjs from "dayjs";
import { signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { LanguageSelector } from "@/components/blocks/global/language-selector/LanguageSelector";
import DefaultLink from "@/components/elements/link/DefaultLink";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";

import NotificationIcon from "~public/icons/Notification Bell/Notification Bell.svg";

interface MoreDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const MoreDrawer = ({
  isOpen,
  handleClose
}: MoreDrawerProps): JSX.Element => {
  const { t } = useTranslation("more-drawer");
  const theme = useTheme();
  const { session } = useAuth();

  const handleLogout = () => {
    signOut({ callbackUrl: "/" });
  };

  return (
    <Drawer
      sx={{ zIndex: 5 }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: theme.variables.vhWithMargins,
          mt: theme.variables.headerHeight,
          zIndex: -50
        }
      }}
      hideBackdrop
      anchor={"right"}
      open={isOpen}
      onClose={handleClose}>
      <Container
        disableGutters
        sx={{
          position: "relative",
          minWidth: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column"
        }}>
        {session && (
          <DefaultLink
            sx={{ position: "absolute", top: 0, right: 0 }}
            href={"/notifications"}>
            <IconButton>
              <NotificationIcon />
            </IconButton>
          </DefaultLink>
        )}

        {session && (
          <Box
            bgcolor={"green.main"}
            px={2}
            py={3}
            display={"flex"}
            flexDirection={"column"}
            gap={1.5}>
            <Box sx={{ display: "flex", gap: 1.25 }}>
              <Avatar
                src={session.user?.avatar_thumbnail_55?.toString()}
                variant={"square"}
                sx={{ width: 44, height: 44, borderRadius: 1.5 }}
              />
              <Box>
                <Typography
                  noWrap
                  fontWeight={
                    400
                  }>{`${session.user.first_name} ${session.user.last_name}`}</Typography>
                <Typography color={"#656565"} fontFamily={"Open Sans"}>
                  {/* todo remove */}
                  {"Your profile"}
                </Typography>
              </Box>
            </Box>
            <DefaultLink
              onClick={handleClose}
              underline={"hover"}
              href="/profile">
              <Typography fontSize={16}>{t("links.my-account")}</Typography>
            </DefaultLink>
            <DefaultLink
              onClick={handleClose}
              underline={"hover"}
              href="/orders">
              <Typography fontSize={16}>{t("links.my-orders")}</Typography>
            </DefaultLink>
            <DefaultLink
              onClick={handleClose}
              underline={"hover"}
              href="/notifications">
              <Typography fontSize={16}>{t("links.notifications")}</Typography>
            </DefaultLink>
          </Box>
        )}
        {session && (
          <Box mt={0.25} px={2} pt={1} pb={2.25} bgcolor={"green.main"}>
            <Button
              variant={"text"}
              fullWidth
              sx={{
                textTransform: "none",
                fontSize: 16,
                fontWeight: 300,
                justifyContent: "start"
              }}
              onClick={handleLogout}>
              {t("signout")}
            </Button>
          </Box>
        )}
        {!session && (
          <Box
            mt={0.25}
            pt={3.5}
            px={2}
            pb={2.25}
            bgcolor={"green.main"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}>
            <DefaultLink
              onClick={handleClose}
              underline={"hover"}
              href="/login">
              <Typography fontSize={16}>{t("signin")}</Typography>
            </DefaultLink>
            <DefaultLink
              onClick={handleClose}
              underline={"hover"}
              href="/login?formType=signup">
              <Typography fontSize={16}>{t("create-account")}</Typography>
            </DefaultLink>
          </Box>
        )}
        <Box
          mt={session ? 0 : 2.5}
          px={2}
          pt={1}
          pb={2.5}
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            justifyContent: "space-between"
          }}>
          <DefaultLink onClick={handleClose} underline={"hover"} href="/chefs">
            <Typography fontSize={16}>{t("links.homechefs")}</Typography>
          </DefaultLink>
          <DefaultLink onClick={handleClose} underline={"hover"} href="/about">
            <Typography fontSize={16}>{t("links.about")}</Typography>
          </DefaultLink>
          <DefaultLink
            onClick={handleClose}
            underline={"hover"}
            href="/contacts">
            <Typography fontSize={16}>{t("links.contacts")}</Typography>
          </DefaultLink>
          <DefaultLink onClick={handleClose} underline={"hover"} href="/faq">
            <Typography fontSize={16}>{t("links.faq")}</Typography>
          </DefaultLink>
          <DefaultLink
            onClick={handleClose}
            underline={"hover"}
            href="/profile?tab=settings">
            <Typography fontSize={16}>{t("links.settings")}</Typography>
          </DefaultLink>
        </Box>
        <Divider sx={{ borderBottomWidth: 2 }} />
        <LanguageSelector />

        <Box
          mx={2}
          pb={2.5}
          pt={4}
          sx={{
            display: "flex",
            columnGap: 2,
            alignItems: "center",
            justifyContent: "end"
          }}>
          <Typography ml={0} mr={"auto"} color={"#98A2B3"}>
            © {dayjs().year()} Cookachu
          </Typography>
          <DefaultLink onClick={handleClose} underline={"hover"} href="/terms">
            <Typography color={"#98A2B3"}>{t("terms")}</Typography>
          </DefaultLink>
          <DefaultLink
            onClick={handleClose}
            underline={"hover"}
            href="/privacy-policy">
            <Typography color={"#98A2B3"}>{t("privacy")}</Typography>
          </DefaultLink>
        </Box>
      </Container>
    </Drawer>
  );
};
