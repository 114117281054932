// Constant for link to all pages
export const navigation = {
  home: "/",
  user: (id: number | string) => `/user/${id}`,
  cart: "/cart",
  checkout: "/checkout",
  contactUs: "/contacts",
  faq: "/faq",
  about: "/about",
  login: "/login",
  createRecipe: "/create-recipe",
  profile: "/profile"
};
