import SettingsApi from "@/api/Settings";

import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query";
import { createEffect, createEvent, createStore } from "effector";
import { useRouter } from "next/router";
import { toast } from "react-hot-toast";
import { GetManyResponse } from "~types/api/base";
import { SettingsApiNS } from "~types/api/settings";
import { Review } from "~types/review";

import GetReviewsResponse = SettingsApiNS.GetReviewsResponse;
import GetReviewsArgs = SettingsApiNS.GetReviewsArgs;
import AddReviewArgs = SettingsApiNS.AddReviewArgs;
import RemoveReviewArgs = SettingsApiNS.RemoveReviewArgs;

enum Keys {
  user = "chef-review",
  platform = "platform-review",
  recipe = "recipe-review"
}

export const toggleReviewModal = createEvent();
export const createPlatformReviewFx = createEffect(
  async (data: AddReviewArgs) => {
    await SettingsApi.addReview(data);
  }
);

export const $isReviewModalOpen = createStore(false).on(
  toggleReviewModal,
  state => !state
);

export const useReviews = () => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const useGetReviews = (
    data: GetReviewsArgs,
    options?: UseInfiniteQueryOptions<GetReviewsResponse>
  ) => {
    return useInfiniteQuery(
      ["review", data.type, data.object_id],
      ({ pageParam = 1 }) =>
        SettingsApi.getReviews({ ...data, page: pageParam }),
      {
        getPreviousPageParam: firstPage => firstPage?.previous || undefined,
        getNextPageParam: lastPage => lastPage?.next || undefined,
        ...options
      }
    );
  };

  const useAddReview = useMutation(
    (data: AddReviewArgs) => SettingsApi.addReview(data),
    {
      onMutate: async data => {
        const queryKey = ["review", data.type, Number(data.object_id)];
        await queryClient.cancelQueries(queryKey);
        const previousReviews = await queryClient.getQueryData(queryKey);
        console.log({ previousReviews });
        console.log(queryKey);
        queryClient.setQueryData(
          ["review", data.type, Number(data.object_id)],
          (reviews: any) => {
            console.log({ reviews });
            const results = reviews.results || { pages: [{ results: [] }] };
            results.pages[0].results.push(data);
            return {
              ...reviews,
              count: results.length,
              results: results
            };
          }
        );
        return { previousReviews, data };
      },
      onError: (error, data, context) => {
        const queryKey = [
          "review",
          context!.data.type,
          context!.data.object_id
        ];
        queryClient.setQueryData(queryKey, context?.previousReviews);
        if ((error as any)?.status === 401) {
          toast.error("Please login to add reviews");
          router.push("/login");
        }
        // toast.error(err?.message || "Can't add to cart. Check network connection");
        toast.error(
          JSON.stringify(
            Object.values((error as any)?.response?.data).flat()
          ) || "Can't add review. Check network connection"
        );
      },
      onSuccess: data => {
        const queryKey = ["review", data.type, data.object_id];
        queryClient.refetchQueries(queryKey);
        toast.success("Review sucessfully uploaded");
      }
    }
  );

  const useRemoveReview = useMutation(
    (data: RemoveReviewArgs) => SettingsApi.removeReview(data),
    {
      onMutate: async data => {
        const queryKey = [Keys[data.type], data.object_id];
        await queryClient.cancelQueries(queryKey);
        const previousReviews = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(
          queryKey,
          (reviews: GetManyResponse<Review>) => {
            const newResults = reviews.results.filter(
              review => review.pk !== data.pk
            );
            return {
              ...reviews,
              count: newResults.length,
              results: newResults
            };
          }
        );
        return { previousReviews, data };
      },
      onError: (err, newCartShort, context) => {
        const queryKey = [Keys[context!.data.type], context!.data.object_id];
        queryClient.setQueryData(queryKey, context?.previousReviews);
        toast.error("Can't remove product. Check network connection");
      }
    }
  );

  return {
    useGetReviews,
    useAddReview,
    useRemoveReview
  } as const;
};
