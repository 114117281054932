import { CartApiNS as NS } from "~types/api/cart";

import http from "../utils/http";

const CartApi = {
  getInfo: () => {
    return http.get<NS.GetInfoResponse>("cart/info");
  },

  getDeliveryPrice: () => {
    return http.get<NS.GetDeliveryCostsResponse>("/order/delivery_price");
  },

  completeOrder: (data: any) => {
    return http.post("/order/complete", data);
  },

  checkPromo: (params: string) => {
    return http.get("settings/promocode", {
      params: { promocode: params }
    });
  },

  //TODO: Доработать логику с версией
  getEngagementFee: () => {
    return http.get<NS.GetEngagementFeeResponse>("/order/engagement_fee/0");
  },

  getProducts: () => {
    return http.get<NS.GetProductsResponse>("/cart/product/list");
  },

  addToCart: (params: NS.AddToCartArgs) => {
    return http.post(`/cart/product/add/recipe/${params.id}/${params.state}`);
  },

  removeFromCart: (params: NS.RemoveFromCartArgs) => {
    return http.delete(`/cart/product/${params.id}`);
  },

  updateInCart: (params: NS.UpdateInCartArgs) => {
    return http.patch<NS.UpdateInCartResponse>(`/cart/product/${params.id}`, {
      count: params.count
    });
  },

  createOrder: (body: NS.CreateOrderArgs) => {
    return http.post<NS.CreateOrderResponse>("/cart/order", body);
  },

  validateZipcode: (params: NS.CheckZipcodeArgs) => {
    return http.get<NS.CheckZipcodeResponse>(
      `/settings/zipcode?code=${params.code}`
    );
  }
};

export { CartApi as default };
