import React from "react";

import { useAuth } from "@/customHooks/useAuth";
import { signOut } from "next-auth/react";

import DefaultLink from "@/components/elements/link/DefaultLink";
import { Button, ButtonGroup, Menu, MenuItem, Typography } from "@mui/material";

export const LoginMenu = ({
  handleClose,
  anchorEl,
  isOpen
}: any): JSX.Element => {
  const { session } = useAuth();

  const handleSignOut = () => {
    signOut({ callbackUrl: "/" });
  };

  return (
    <div>
      {!session && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          disableScrollLock
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}>
          <MenuItem onClick={handleClose}>
            <ButtonGroup
              disableElevation
              variant="text"
              aria-label="text button group">
              <Button
                href={"/login"}
                sx={{
                  textTransform: "none",
                  px: 1,
                  fontSize: [14, 14, 14, 14, 18],
                  fontWeight: 300
                }}>
                Sign In
              </Button>
              <Button
                href={"/login?formType=signup"}
                sx={{
                  textTransform: "none",
                  px: 1,
                  fontSize: [14, 14, 14, 14, 18],
                  fontWeight: 300
                }}>
                Create account
              </Button>
            </ButtonGroup>
          </MenuItem>
        </Menu>
      )}
      {session && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          disableScrollLock
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}>
          <MenuItem onClick={handleClose}>
            <DefaultLink underline={"none"} href={"/profile"}>
              <Typography fontSize={[14, 14, 14, 14, 18]}>
                My account
              </Typography>
            </DefaultLink>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <DefaultLink underline={"none"} href={"/orders"}>
              <Typography fontSize={[14, 14, 14, 14, 18]}>My orders</Typography>
            </DefaultLink>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <DefaultLink underline={"none"} href={"/notifications"}>
              <Typography fontSize={[14, 14, 14, 14, 18]}>
                Notifications
              </Typography>
            </DefaultLink>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Typography onClick={handleSignOut} fontSize={[14, 14, 14, 14, 18]}>
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};
