import { omit } from "lodash";
import { AccountApiNS as NS } from "~types/api/account";

import http from "../utils/http";

export interface SocialMedia {
  first_audio: null | string;
  second_audio: null | string;
  third_audio: null | string;
}

const AccountApi = {
  //  Login\register\refresh\
  login: (body: NS.LoginArgs) => {
    return http.post<NS.LoginResponse>("/token/", body, {
      validateStatus: status => status === 200
    });
  },

  register: (body: NS.RegisterArgs) => {
    if (!body.user_type) {
      body.user_type = 1;
    }
    try {
      if (body?.ref_token) {
        return http.post<NS.RegisterResponse>(
          `account/register/${body.ref_token}`,
          omit(body, ["ref_token"])
        );
      }

      return http.post<NS.RegisterResponse>("account/register", body);
    } catch (e) {
      return e?.response;
    }
  },

  refreshToken: (body: NS.RefreshTokenArgs) => {
    return http.post<NS.RegisterResponse>("/token/refresh/", body);
  },

  current: () => {
    return http.get<NS.GetCurrentAccountResponse>("/account/me");
  },

  updateProfileUser: (body: NS.UpdateCurrentAccountArgs) => {
    if (body.avatar instanceof File) {
      const bodyWithNoAvatar = omit(body, ["avatar"]);
      const formData = new FormData();
      formData.append("avatar", body.avatar);
      for (let key in bodyWithNoAvatar) {
        formData.append(
          key,
          bodyWithNoAvatar[key as keyof typeof bodyWithNoAvatar]
        );
      }

      return http.patch<NS.UpdateCurrentAccountResponse>(
        "account/me",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    }

    if (typeof body.avatar === "string") {
      return http.patch<NS.UpdateCurrentAccountResponse>(
        "account/me",
        omit(body, ["avatar"])
      );
    }

    return http.patch<NS.UpdateCurrentAccountResponse>("account/me", body);
  },

  confirmEmail: (body: NS.EmailConfirmArgs) => {
    return http.post("/account/email/confirm", body);
  },

  changePassword: (body: NS.ChangePasswordArgs) => {
    return http.post<NS.ChangePasswordResponse>(
      "/account/password/change",
      body
    );
  },

  // RESET PASSWORD
  resetPassword: (body: NS.ResetPasswordArgs) => {
    return http.post<NS.ResetPasswordResponse>("account/password/reset", body);
  },

  checkResetPasswordCode: (body: NS.CheckResetPasswordCodeArgs) => {
    return http.post<NS.CheckResetPasswordCodeResponse>(
      "account/password/reset/check",
      body
    );
  },

  setNewPassword: (body: NS.SetNewPasswordArgs) => {
    return http.post("account/password/new", body);
  },

  // ADDRESS
  getAddress: (params: NS.GetAddressArgs) => {
    return http.get<NS.GetAddressResponse>(`account/address/${params.type}`);
  },

  updateAddress: (body: NS.UpdateAddressArgs) => {
    return http.put<NS.UpdateAddressResponse>(
      `account/address/${body.type}`,
      body
    );
  },

  createAddress: (body: NS.CreateAddressArgs) => {
    return http.post<NS.CreateAddressResponse>("account/address/", body);
  },

  getUserById: (params: NS.GetUserByIdArgs) => {
    const result = http.get<NS.GetUserByIdResponse>(`/account/${params.id}`);
    console.log({ result });
    return result;
  },

  getUsersList: (params?: NS.GetUsersListArgs) => {
    return http.get<NS.GetUsersListResponse>("account/homechef_list", {
      params
    });
  },

  sendTip: (body: NS.SendTipArgs) => {
    return http.post("account/tipping", body);
  },

  getNotifications: () => {
    return http.get<NS.GetNotificationsResponse>("/notifications");
  },

  readNotification: (id: number) => {
    return http.patch(`/notifications/view/${id}`);
  },

  deleteNotification: (params: NS.RemoveNotificationByIdArgs) => {
    return http.delete(`/notifications/${params.id}/`, {
      validateStatus: status => status === 204
    });
  },

  deleteAllNotifications: () => {
    return http.delete("/notifications/delete_all", {
      validateStatus: status => status === 204
    });
  },

  addOrRemoveLikeOnUser: (params: { pk: number }) => {
    return http.post("/account/like", params);
  },

  getSocialPage: () => {
    return http.get<SocialMedia>("/social/splash_page");
  },

  getUberSocialAudio: () => {
    return http.get("/social/uber_page");
  },

  getSplashPageInfo: (companyName: string) => {
    return http.get<NS.GetSplashPageInfoResponse>(
      `/page_editor/splash/companies/${companyName}`
    );
  },

  getSplashPageList: () => {
    return http.get<NS.SplashPageList[]>("/page_editor/splash/companies/");
  }
};

export default AccountApi;
