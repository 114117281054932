import React, { MouseEventHandler, useState } from "react";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

import { NextLinkComposed } from "@/components/elements/link/DefaultLink";
import { Box, Button, Typography, useTheme } from "@mui/material";

//icons
import MenuSplash from "~public/icons/Menu Selected Splash/Menu Selected Splash.svg";

interface NavButtonBaseProps {
  label: string;
  path?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  isDrawerOpen: boolean;
  name: string;
}

const NavButtonBase = ({
  label,
  path,
  onClick,
  isDrawerOpen,
  name
}: NavButtonBaseProps) => {
  const router = useRouter();
  const theme = useTheme();
  const isSelected = isDrawerOpen ? name === "more" : router.pathname === path;
  return (
    <Button
      onClick={onClick}
      variant={"text"}
      component={NextLinkComposed}
      sx={{
        flex: "1 1 auto",
        width: "max-content",
        p: 0,
        gap: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "& .splash path": { fill: theme.palette.pink.main }
      }}
      to={{
        pathname: path
      }}
      id={`navbar-button-${name}`}
      data-qa={`navbar-button-${name}`}>
      <Typography
        sx={{
          whiteSpace: "nowrap",
          fontSize: { xs: 12, md: 18 },
          fontWeight: { xs: 400, md: 300 },
          textTransform: "none",
          zIndex: 1,
          color: "white.main"
        }}>
        {label}
      </Typography>
      <MenuSplash
        className="splash"
        style={{
          position: "absolute",
          visibility: isSelected ? "visible" : "hidden",
          top: -11,
          left: 11,
          zIndex: 0,
          fontSize: 43
        }}
      />
    </Button>
  );
};

export const NavBarDesktop = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const Home = () => (
    <NavButtonBase
      label={t("home")}
      path="/"
      name="home"
      isDrawerOpen={isOpen}
      onClick={handleClose}
    />
  );
  const Recipes = () => (
    <NavButtonBase
      label={t("common:stories")}
      path="/search"
      name="search"
      isDrawerOpen={isOpen}
      onClick={handleClose}
    />
  );
  const Chefs = () => (
    <NavButtonBase
      label={t("common:homechefs")}
      path="/chefs"
      name="chefs"
      isDrawerOpen={isOpen}
      onClick={handleClose}
    />
  );
  const About = () => {
    return (
      <NavButtonBase
        label={t("common:aboutUs")}
        path={"/about"}
        name="about"
        isDrawerOpen={isOpen}
        onClick={handleClose}
      />
    );
  };
  const ContactUs = () => {
    return (
      <NavButtonBase
        label={t("common:contactUs")}
        path={"/contacts"}
        name="contacts"
        isDrawerOpen={isOpen}
        onClick={handleClose}
      />
    );
  };
  const Faq = () => {
    return (
      <NavButtonBase
        label={t("common:faq")}
        path={"/faq"}
        name="faq"
        isDrawerOpen={isOpen}
        onClick={handleClose}
      />
    );
  };

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        component={"nav"}
        gap={2}
        sx={{
          justifyContent: "space-evenly",
          alignItems: "center",
          height: theme.variables.navBarHeight
        }}>
        <Home />
        <Recipes />
        <Chefs />
        <About />
        <ContactUs />
        <Faq />
      </Box>
    </React.Fragment>
  );
};
