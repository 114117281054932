import React from "react";

import { TextField } from "@mui/material";
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps
} from "@mui/material/TextField/TextField";

type MemoizedTextFieldProps =
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps;

export const MemoizedTextField = React.memo((props: MemoizedTextFieldProps) => (
  <TextField {...props} />
));

MemoizedTextField.displayName = "MemoizedTextField";
