import React, { useState } from "react";

import { createPlatformReviewFx, useReviews } from "@/customHooks/useReview";
import { useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { SettingsApiNS } from "~types/api/settings";

import { TextFieldWithMaxSymbols } from "@/components/elements/text-field-with-max-symbols/TextFieldWithMaxSymbols";
import {
  Button,
  CircularProgress,
  Container,
  Modal,
  ModalProps,
  Rating,
  Typography
} from "@mui/material";

import AddReviewArgs = SettingsApiNS.AddReviewArgs;

interface ReviewModalProps {
  open: boolean;
  onClose: ModalProps["onClose"];
}

const reviewInitialValues: AddReviewArgs = {
  rating: 0,
  review_text: "",
  type: "platform",
  object_id: 0
};

export const ReviewModal = (props: any): JSX.Element => {
  const { open, onClose, queryObject } = props;
  const { useAddReview } = useReviews();
  // const { isLoading } = useAddReview;

  const { t } = useTranslation("homepage");

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const formik = useFormik<typeof reviewInitialValues>({
    initialValues: reviewInitialValues,
    onSubmit: async values => {
      setError("");
      setIsLoading(true);
      createPlatformReviewFx(values)
        .then(() => {
          setSuccess(true);
        })
        .catch(() => {
          setError(t("modal.error"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  });

  return (
    <Modal
      sx={{ mx: 2, display: "flex", alignItems: "center" }}
      open={open}
      onClose={onClose}>
      <Container
        sx={{
          bgcolor: "white.main",
          py: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          maxWidth: { md: "600px" }
        }}>
        {!success && (
          <>
            <Typography fontSize={16} fontWeight={400} my={1}>
              {t("feedback.review-modal.title")}
            </Typography>
            <form
              action="post-review"
              onSubmit={e => {
                e.preventDefault();
                formik.handleSubmit();
              }}>
              <Rating
                name={"rating"}
                sx={{ my: 1 }}
                value={formik.values.rating}
                onChange={formik.handleChange}
              />

              <TextFieldWithMaxSymbols
                maxLength={300}
                multiline={true}
                rows={5}
                name={"review_text"}
                error={Boolean(formik.errors.review_text)}
                helperText={formik.errors.review_text}
                placeholder={t("feedback.review-text.placeholder")}
                value={formik.values.review_text}
                onChange={formik.handleChange}
                variant={"outlined"}
                top={"-14px"}
                sx={{
                  mt: 0.75,
                  mb: 1.5,
                  width: "100%",
                  "& > div": {
                    p: 0
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "5px"
                  }
                }}
              />
              <Button
                fullWidth
                type="submit"
                color={"green"}
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}>
                {t("feedback.review-modal.post-button")}
              </Button>
            </form>
          </>
        )}
        {error && !success && (
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={16}
            fontWeight={400}
            my={1}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={16}
            fontWeight={400}
            my={1}>
            {t("modal.success")}
          </Typography>
        )}
      </Container>
    </Modal>
  );
};
