import React, { ReactNode } from "react";

import { Global } from "@emotion/react";

import {
  Box,
  SwipeableDrawer,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme
} from "@mui/material";

export interface IBottomDrawerWithEdge {
  isOpen: boolean;
  toggleDrawer: (state: boolean) => void;
  children: JSX.Element | JSX.Element[] | ReactNode;
  height?: string | number;
  backgroundColor?: string;
  edgeIcon?: ReactNode;
  disableGutters?: boolean;
  sx?: SxProps<Theme>;
}

export const SwipeableEdgeDrawer = (
  props: IBottomDrawerWithEdge
): JSX.Element => {
  const {
    isOpen,
    toggleDrawer,
    children,
    height,
    backgroundColor,
    edgeIcon,
    disableGutters,
    sx
  } = props;

  const theme = useTheme();
  const isGreaterThanTablet = useMediaQuery(theme.breakpoints.up("md"));

  const setClose = () => {
    toggleDrawer(false);
  };

  const setOpen = () => {
    toggleDrawer(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        backgroundColor: "white"
      }}>
      <Global styles={{}} />
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={setClose}
        onOpen={setOpen}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          backdropFilter: "blur(2px)",
          "& > .MuiPaper-root": {
            height: height ? height : "auto",
            overflow: "visible",
            borderTopLeftRadius: { xs: 20, md: 0 },
            borderTopRightRadius: { xs: 20, md: 0 },
            backgroundColor
          }
        }}>
        {!isGreaterThanTablet && (
          <Box
            sx={{
              width: 20,
              height: 4,
              backgroundColor: edgeIcon ? "transparent" : "#667085",
              borderRadius: 2,
              position: "absolute",
              top: 6,
              left: "calc(50% - 10px)"
            }}>
            {edgeIcon}
          </Box>
        )}

        <Box
          sx={{
            mt: 5,
            px: disableGutters ? 0 : 2.5,
            pb: 2,
            height: "100%",
            overflow: "auto",
            backgroundColor: backgroundColor || "#fff",
            ...sx
          }}>
          {children}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
