import { RecipeApiNS as NS } from "~types/api/recipe";

import http from "../utils/http";

const RecipeApi = {
  // RECIPE
  getRecipe: (params: NS.GetRecipeArgs) => {
    return http.get<NS.GetRecipeResponse>(`/recipe/${params.recipeId}`);
  },

  getPromoRecipe: (params: NS.GetRecipeArgs) => {
    return http.get<NS.GetRecipeResponse>(`/recipe/promo/${params.recipeId}`);
  },

  getPromoRecipePage: (companyName: string) => {
    return http.get(`/page_editor/promo/companies/${companyName}`);
  },

  getRecipePDFUrl: (params: NS.GetRecipePdfUrlArgs) => {
    return http.get<NS.GetRecipePdfUrlResponse>(
      `/recipe/download/${params.recipeId}`,
      { timeout: 50000 }
    );
  },

  removeRecipe: (params: NS.RemoveRecipeArgs) => {
    return http.delete(`/recipe/${params.recipeId}`);
  },

  getRecipes: (params?: NS.GetRecipesArgs) => {
    return http.get<NS.GetRecipesResponse>("/recipe/production", {
      params
    });
  },

  getVoteRecipes: (params?: NS.GetRecipesArgs) => {
    return http.get<NS.GetRecipesResponse>("/recipe", {
      params
    });
  },

  getMyRecipes: (params?: NS.GetRecipesArgs) => {
    return http.get("/recipe/my", {
      params
    });
  },

  getSearchSuggestions: (lang: string, search: string = "") => {
    return http.get("/recipe/search_suggestions", {
      params: {
        lang: lang,
        search: search
      }
    });
  },

  uploadStepImage: (body: NS.UploadStepImageArgs) => {
    const formData = new FormData();
    formData.append("file", body.file);
    return http.post<NS.UploadStepImageResponse>(
      "recipe/upload_step_image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },

  uploadRecipe: (body: NS.UploadRecipeArgs) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(body));
    return http.post<NS.UploadRecipeResponse>("recipe/", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  countAudioListen: (id: number | string, params: boolean, hash: string) => {
    return http.post(`/recipe/audio/${id}`, {
      listen_count: params,
      hashed_id: hash
    });
  },

  uploadAudio: (body: NS.UploadAudioArgs) => {
    const formData = new FormData();
    formData.append("audio", body.audio);
    formData.append("type", "1");
    if (body.name) {
      formData.append("name", body.name);
    }
    return http.post<NS.UploadAudioResponse>("/recipe/audio_upload", formData, {
      onUploadProgress: data => {
        body.setLoadingProgress &&
          body.setLoadingProgress(Math.round((100 * data.loaded) / data.total));
      },
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 60000
    });
  },

  // BASIC INGREDIENTS
  getBasicIngredients: (params?: NS.GetBasicIngredientsArgs) => {
    return http.get<NS.GetBasicIngredientsResponse>(
      "/recipe/basic_ingredients",
      { params }
    );
  },

  createBasicIngredient: ({ lang, ...body }: NS.CreateBasicIngredientArgs) => {
    return http.post<NS.CreateBasicIngredientResponse>(
      "/recipe/basic_ingredients",
      body
    );
  },

  getIngredientsGroups: (params?: NS.GetIngredientGroupsArgs) => {
    return http.get<NS.GetIngredientGroupsResponse>(
      "/recipe/ingredient_groups",
      { params }
    );
  },

  // FAVOURITE FOLDERS
  getFolder: (params: NS.GetFolderArgs) => {
    return http.get<NS.GetFoldersResponse>(
      `/account/favorite/${params.folderName}/list`
    );
  },

  getFolders: (params?: NS.GetFoldersArgs) => {
    return http.get<NS.GetFoldersResponse>("/account/favorite", {
      params
    });
  },

  createFolder: (body: NS.CreateFolderArgs) => {
    return http.post<NS.CreateFolderResponse>("/account/favorite", body);
  },

  editFolder: (body: NS.EditFolderArgs) => {
    return http.patch<NS.EditFolderResponse>(
      `/account/favorite/${body.folderId}`,
      {
        name: body.newName
      }
    );
  },

  removeFolder: (params: NS.RemoveFolderArgs) => {
    return http.delete<NS.RemoveFolderResponse>(
      `/account/favorite/${params.name}/list`
    );
  },

  addToFolder: (params: NS.AddToFolderArgs) => {
    return http.post<NS.AddToFolderResponse>(
      `/account/favorite/${params.name}/recipe/${params.object_id}`
    );
  },

  removeFromFolder: (params: NS.RemoveFromFolderArgs) => {
    return http.delete<NS.RemoveFolderResponse>(
      `/account/favorite/${params.name}/${params.object_id}`
    );
  },

  addOrRemoveLike: (params: NS.AddOrRemoveLikeArgs) => {
    return http.post<NS.AddOrRemoveLikeResponse>(
      `/recipe/${params.recipeId}/like`
    );
  },

  addOrRemoveDislike: (params: NS.AddOrRemoveDislikeArgs) => {
    return http.post<NS.AddOrRemoveDislikeResponse>(
      `/recipe/${params.recipeId}/dislike`
    )
  },

  likePromoRecipe: (params: NS.LikeOrDislikePromoRecipe) => {
    return http.post(`/recipe/promo/${params.company}/${params.id}/like`)
  },
  dislikePromoRecipe: (params: NS.LikeOrDislikePromoRecipe) => {
    return http.post(`/recipe/promo/${params.company}/${params.id}/dislike`)
  }
} as const;

export { RecipeApi as default };
